<template>
  <BasicInformationCard title="Taken" :promises.sync="promises">
    <template v-slot:content>
      <overview :items="tasks" :headers="headers" :filters="filters" add-btn-text="Nieuw taak"
        :add-item-component="pushToCreateRoute" :succesFunction="fetchTasks" :detail-page-function="detailPageFunction"
        :showCreateButton="getProjectStatus" :disable-pagination="false">
        <template v-slot:item.taskStatus="{ item }">
          <v-chip :color="getTaskStatusColor(item.taskStatus)" label class="max-h-36px">
            {{ translateTaskStatus(item.taskStatus) }}
          </v-chip>
        </template>
      </overview>
    </template>
  </BasicInformationCard>
</template>

<script>

import RouteNames from "@/router/RouteNames";
import Overview from "@/components/shared/Overview";
import BasicInformationCard from "@/components/shared/BasicInformationCard.vue";
import { translateTaskStatus, getTaskStatusColor } from "@/shared/utils/translateUtils";

export default {
  name: "ProjectDetailTaskListComponent",
  components: {
    BasicInformationCard,
    Overview,
  },
  props: {
    clientId: {
      type: String,
      required: true
    },
    projectId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      headers: [
        {
          text: "Id",
          value: 'functionalId',
          cellClass: 'notranslate'
        },
        {
          text: "Naam",
          value: 'taskName',
          cellClass: 'notranslate'
        },
        {
          text: 'Status',
          value: 'taskStatus'
        },
      ],
      filters: [
        {
          title: "Status",
          options: [
            {
              id: 'task#status#active',
              label: 'Actief',
              active: false,
              predicate: (task) => task.taskStatus && task.taskStatus === "ACTIVE"
            },
            {
              id: 'task#status#cancelled',
              label: 'Geannuleerd',
              active: false,
              predicate: (task) => task.taskStatus && task.taskStatus === "CANCELLED"
            },
            {
              id: 'task#status#done',
              label: 'Gedaan',
              active: false,
              predicate: (task) => task.taskStatus && task.taskStatus === "DONE"
            },
          ]
        }
      ],
      tasks: [],
      detailPageFunction: (taskDTO) => {
        return {
          name: RouteNames.TASK_DETAIL,
          params: {
            projectId: this.projectId,
            clientId: this.clientId,
            functionalId: taskDTO.functionalId
          }
        }
      },
      promises: [this.fetchTasks()]
    }
  },
  methods: {
    translateTaskStatus,
    getTaskStatusColor,
    fetchTasks() {
      return this.$store.dispatch('tasksModule/fetchTasksForClientAndProject', { clientId: this.clientId, projectId: this.projectId })
        .then((result) => this.tasks = result)
    },
    pushToCreateRoute() {
      this.$router.push({ name: RouteNames.TASK_CREATE, params: { clientId: this.clientId, projectId: this.projectId } })
    },
  },

  computed: {
    getProjectStatus() {
      return this.$store.state.projectsModule.project?.active
    },

  }
}
</script>
