<template>
    <BasicInformationCard title="Project informatie"
        :editRouterLink="{ name: routeNames.PROJECT_DETAIL_EDIT_PROJECT_INFORMATION, params: { clientId: clientId, projectId: projectId } }"
        :promises.sync="promises">
        <template v-slot:content>
            <v-container>
                <v-row>
                    <v-col cols="12" md="7">
                        <v-row>
                            <v-col cols="6">
                                <BasicInformationItem description="Start datum"
                                    :value="applyDateFormat(projectInformation.startDate)" />
                            </v-col>
                            <v-col cols="6">
                                <BasicInformationItem description="Eind datum"
                                    :value="applyDateFormat(projectInformation.endDate)" />
                            </v-col>
                          <v-col cols="6">
                            <BasicInformationItem v-if="isCIAOEnabled" description="Aangifte van werken"
                                                  :value="projectInformation.declarationOfWork" noTranslate/>
                          </v-col>
                            <v-col cols="6">
                                <BasicInformationItem description="Adres" :value="projectInformation.address">
                                    <template v-slot:value-display="slotProps">
                                        <p v-if="slotProps.value" class="subtitle-1 font-weight-medium black--text">
                                            <span class="notranslate">{{ slotProps.value.street }} {{ slotProps.value.number }} {{ slotProps.value.bus}}</span>
                                            <br />
                                            {{ slotProps.value.postalCode }} {{ slotProps.value.city }} {{
                                                slotProps.value.region }}
                                            <br />
                                            <country-flag v-if="slotProps.value.countryCode"
                                                :country="slotProps.value.countryCode" size="small" rounded /> {{
                                                    translateNationalitiy(slotProps.value.countryCode) }}
                                        </p>
                                    </template>
                                </BasicInformationItem>
                            </v-col>
                        </v-row>
                    </v-col>
                    <GoogleMapsFrame :mapsURL="getGoogleMapsUrl(projectInformation)" 
                    unavailableMessage="Er is geen adres beschikbaar" :staySmallAboveMedium=true />
                </v-row>
            </v-container>
        </template>
    </BasicInformationCard>
</template>

<script>
import BasicInformationCard from '../shared/BasicInformationCard.vue';
import BasicInformationItem from '../shared/BasicInformationItem.vue';
import routeNames from "@/router/RouteNames";
import { translateNationalitiy } from "@/shared/utils/translateUtils";
import CountryFlag from 'vue-country-flag'
import { applyDateFormat } from "@/shared/utils/dateUtils"
import { getGoogleMapsUrl } from "@/shared/utils/requestUtils"
import GoogleMapsFrame from "@/components/shared/GoogleMapsFrame.vue"


export default {
    name: "ProjectInformationComponent",
    props: {
        clientId: {
            type: String,
            required: true
        },
        projectId: {
            type: String,
            required: true
        }
    },
    components: {
        BasicInformationCard,
        BasicInformationItem,
        CountryFlag,
        GoogleMapsFrame,
    },
    data() {
        return {
            routeNames,
            projectInformation: {
                startDate: null,
                endDate: null,
                address: {},
                declarationOfWork: null
            },
            promises: [
                this.$store.dispatch("projectsModule/fetchProjectInformation", { clientId: this.clientId, projectId: this.projectId })
                    .then(result => {
                        this.projectInformation = result
                    }),
                this.$store.dispatch('locationRegistrationConfigModule/fetchLocationConfig')
            ]
        }
    },
    methods: {
        translateNationalitiy,
        applyDateFormat,
        getGoogleMapsUrl,
    },
    computed: {
      isCIAOEnabled() {
        return this.$store.getters["locationRegistrationConfigModule/getLocationConfig"]?.ciaoConfig?.ciaoEnabled === true
      },
    }

}
</script>