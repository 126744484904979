<template>
    <div>
        <ProjectDetailHeaderComponent :client-id="clientId" :project-id="projectId"/>
        <v-row>
            <v-col cols="12" lg="7" class="d-flex">
                <ProjectInformationComponent :client-id="clientId" :project-id="projectId" class="flex-grow-1"/>
            </v-col>
            <v-col cols="12" lg="5" class="d-flex">
                <ProjectContactInformationComponent :client-id="clientId" :project-id="projectId" class="flex-grow-1"/>
            </v-col>
            <v-col cols="12" class="d-flex" v-if="planningModuleInstalled">
                <ProjectDetailTaskListComponent :client-id="clientId" :project-id="projectId" class="flex-grow-1"/>
            </v-col>
            <v-col cols="12" class="d-flex" height="50vh">
                <ProjectImagesComponent :client-id="clientId" :project-id="projectId" class="flex-grow-1"/>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ProjectDetailHeaderComponent from '../../components/projectDetail/ProjectDetailHeaderComponent.vue'
import ProjectInformationComponent from '../../components/projectDetail/ProjectInformationComponent.vue'
import routeNames from "@/router/RouteNames";
import ProjectContactInformationComponent from '../../components/projectDetail/ProjectContactInformationComponent.vue'
import ProjectImagesComponent from '../../components/projectDetail/ProjectImagesComponent.vue';
import ProjectDetailTaskListComponent from '../../components/projectDetail/ProjectDetailTaskListComponent.vue'
import ModuleType from "@/shared/enums/moduleType"

export default {
    name: "ProjectDetail",
    components: {
        ProjectDetailHeaderComponent,
        ProjectInformationComponent,
        ProjectContactInformationComponent,
        ProjectImagesComponent,
        ProjectDetailTaskListComponent
    },
    props: {
        clientId: {
            type: String,
            required: true
        },
        projectId: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            routeNames,
        }
    },
    computed:{
        planningModuleInstalled() {
            return this.$store.getters["organizationModule/organizationHasModule"]([ModuleType.PLANNING])
        }
    }
}
</script>